.se-toolbar {
    background-color: white !important;
}

.se-btn-tray {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    margin: 0 !important;
}

.se-navigation {
    background-color: white !important;
}

.se-menu-list li {
    margin-right: 30px !important;
}