@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.borderCustom {
  border: 2px solid red;
}

ol {
  counter-reset: item;
}
li.clauses-number {
  display: block;
  margin: 0.2% 0%;

  &:before {
    font-weight: bold;
    content: counters(item, ".") " ";
    counter-increment: item;
  }
}

ol.paragraphs.clauses-number {
  list-style: none;
}

ol.paragraphs.clauses-number > li:before {
  content: attr(numbering) ". ";
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 20px;
  border: 3px solid white;
}

.custom-container-scroll {
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 2%;
}

.grecaptcha-badge {
  display: none !important;
}
