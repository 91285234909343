.container_text {
  padding: 2%;
  &__clause-text {
    @extend .container_text;
    padding-left: 5%;
  }
  &__subclause {
    @extend .container_text;
    padding-left: 10% !important;
  }
}

.selected-option {
  background-color: $primaryBorderColorActive !important;
  border-color: $primaryColorDefault !important;
}

.container-blankspaces {
  border: solid 1px gray;
  border-radius: 10px;
  &__subclauses {
    @extend .container-blankspaces;
  }
}
