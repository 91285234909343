// PRIMARY COLORS ------------------------------------
$primaryColorDefault: #00374f;
$primaryColorHover: #045380;
$primaryColorActive: #067bbd;
$primaryBorderColorDefault: #ffffff;
$primaryBorderColorHover: #e6e9ef;
$primaryBorderColorActive: #e2eaed;

// SECONDARY COLORS ----------------------------------
$secondaryColorDefault: #e7f9fc;
$secondaryColorHover: #cbecee;
$secondaryColorActive: #95d0d3;

// DISABLED COLORS -----------------------------------
$disabledColorDefault: #e6e9ef;
$disabledColorBorder: #c5c7d0;

// MENU COLORS ----------------------------------------
$primaryMenuColor: #f6f6f7;
$secondaryMenuColor: #fafcfd;
$focusMenuColor: #e2eaed;

//TEXT COLORS -----------------------------------------
$disableTextColor: #676879;
$defaultTextColor: #393b53;
$subtitleTextColor: #323338;
$headingTextColor: $primaryColorDefault;
$linkTextColor: $primaryColorActive;
$textGray: #8c8d9d;
//BACKGROUND COLORS ----------------------------------------
$backgroundMenusColor: #f1f3f6;
//OTHER COLORS ----------------------------------------
$otherColorDefault: #17b4bc;
$otherColorHover: #16cad3;
$otherColorActive: #16cad3;
$otherColorDefaultDark: #ccf4e3;

//FEEDBACK COLORS ------------------------------------
$errorColorDark: #e44258;
$informationColorDark: #71c9c1;
$warningColorDark: #f9600b;
$successColorDark: #00ca72;

//IA Colors

$IaColor: linear-gradient(97deg, #16cad3 0%, #16d384 100%);
$infoColor: #4dcafa;

//IA Colors

$IaColor: linear-gradient(97deg, #16cad3 0%, #16d384 100%);
