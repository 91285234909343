.sun-editor {
  @extend .input-group-container;
  @extend .label;
  border: 0;
  &:hover {
    border: 0;
  }
  &:focus-within {
    border: 0;
  }
}

.clause-part-text {
  font-weight: bold;
  color: $primaryColorActive;
}

.blank-space-text {
  font-weight: bold;
  color: $defaultTextColor;
  word-break: break-word;
}
