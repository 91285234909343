.custom-badges {
  border-radius: 4px;
  padding: 5px;
  color: $defaultTextColor;

  &__disabled {
    @extend .custom-badges;
    background-color: $disabledColorDefault;
    
  }

  &__secundary {
    @extend .custom-badges;
    background-color: $secondaryColorHover;
    
  }
  &__tertiary {
    @extend .custom-badges;
    background-color: $otherColorHover;
    
  }
  &__error {
    @extend .custom-badges;
    background-color: #F9DADE;
  }
  &__delete-button{
    cursor: pointer;
  }
}
