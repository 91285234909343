.dropzone {
  width: 16rem;
  height: 17rem;
  border-radius: 17px;
  background: white;
  padding: 1.5rem;
  position: relative;
  transition: all 300ms;

  &_input {
    opacity: 0;
    width: 9rem;
    height: 3.6rem;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    margin: 1rem 10rem 0 7.5rem;
  }

  &_upload {
    opacity: 2;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0;
    left: -10000px;
    cursor: pointer;
    margin: 1rem 10rem 0 7.5rem;
  }

  &_btn_error {
    background-color: $errorColorDark;
    color: #ffffff;
    border-radius: 0.4rem;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 1rem;
    align-items: flex-end;
  }
}
