.custom-accordion {
  border-radius: 8px !important;
  border: 1px solid #e5e5e5;
  box-shadow: none !important;
  margin: 1% 0% !important;
  border-color: #e5e5e5;

  & .MuiAccordionSummary-root {
    border-radius: 8px !important;
    border-bottom: 1px solid #e5e5e5 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  &__import-numeral {
    border-radius: 0.8rem !important;
    box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px !important;
    margin: 0.5% 0% !important;
  }

  &__container {
    overflow-y: scroll;
    height: calc(100vh - 25rem);
  }

  &__container-document {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 15rem);
  }

  &__container-headers {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 20rem);
  }

  &__container-document-folder {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 29rem);
  }

  &__container__small {
    overflow-y: scroll;
    height: 100%;
    max-height: calc(100vh - 30rem);
  }

  &__container__small-2 {
    overflow-y: scroll;
    height: 100%;
    max-height: calc(100vh - 32rem);
  }

  &__container-dashboard {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 2rem);
  }
}

.custom-accordion-summary-rows { 
  border-bottom: 1px solid $disabledColorBorder;
  padding: 8px;
  align-items: center;
  cursor: pointer;
}

.custom-accordion-summary-rows-last-child {
  padding: 8px;
  align-items: center;
  cursor: pointer;
}

.custom-accordion-summary-rows:hover {
  background-color: $primaryBorderColorHover;
}

.custom-accordion-summary-rows-last-child:hover {
  background-color: $primaryBorderColorHover;
}

.custom-accordion-summary-rows-selected {
  @extend .custom-accordion-summary-rows;
  background-color: $primaryBorderColorActive;
}

.custom-accordion-summary-rows-last-child-selected {
  @extend .custom-accordion-summary-rows-last-child;
  background-color: $primaryBorderColorActive;
}

