.version-container {
    padding: 2% 5%;
    &__box {
      @extend .version-container;
      box-shadow: 0px 0px 0px 1px #E1E3E5;
      &__subtitle {
        @extend .version-container__box;
        justify-content: center;
        margin: 0 0.1% !important;
        width: 100%;
        border-radius: 10px 10px 0 0;
      }
      &__selected {
        @extend .version-container__box;
        background-color: $disabledColorDefault;
      }
    }
  }
  
  .icon-version {
    fill: $successColorDark !important;
  }
  
  .general-container-version {
      margin: 5% 0;
      &__title {
        align-items: center;
        padding: 5% 0 2% 0;
      }
  }