.col-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text-description {
  @extend .caption;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1%;
}

.heading-black {
  @extend .heading;
  @extend .col-start;
  color: $defaultTextColor;

  &__title {
    @extend .heading-black;
    padding-left: 0.5%;
  }
}

.field-container {
  width: 40% !important;
}

.add-part {
  @extend .button-softblue-folders;
  width: 137px !important;

  &__container {
    @extend .col-end;
    padding-top: 1%;
    width: 41%;
  }

  &__disabled {
    @extend .add-part;
    background-color: $disabledColorBorder !important;
    color: $disableTextColor !important;
  }
}

.footer-button {
  width: 103px;
  &__primary {
    @extend .button-primary-folders;
    @extend .footer-button;
  }

  &__softblue {
    @extend .button-softblue-folders;
    @extend .footer-button;
  }
}

.number-clauses {
  @extend .caption__primary-color__active;
  @extend .col-flex;
}
