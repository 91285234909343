.btn {
  &_white_background {
    background-color: #e7f9fc;
    color: #00374f;
    border-radius: 0.4rem;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 1rem;
    align-items: flex-end;
  }

  &_blue_background {
    background-color: #00374f;
    color: #ffffff;
    border-radius: 0.4rem;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 1rem;
    align-items: flex-end;
  }

  &-icon-deny {
    width: 36px;
    height: 36px;
    border: 0px;
    color: #ffffff;
    border-radius: 4px;
    background-color: $errorColorDark;
  }

  &-icon-success {
    width: 36px;
    height: 36px;
    border: 0px;
    color: #ffffff;
    border-radius: 4px;
    background-color: $successColorDark;
  }

  &-icon-blue {
    width: 36px;
    height: 36px;
    border: 0px;
    color: #ffffff;
    border-radius: 4px;
    background-color: $primaryColorDefault;
  }

  &_disable {
    background-color: #e6e9ef;
    color: #676879;
    border-radius: 0.4rem;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 1rem;
    align-items: flex-end;
  }
}

.btn-breadcrum:hover {
  color: "white";
  background-color: #00374f;
}
.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
