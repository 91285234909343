.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.header {
  width: auto;
}

.header p {
  text-align: center;
}

.cloudform {
  position: relative;
}

.textarea {
  position: relative;
  resize: none;

  padding: 1rem 2rem 1rem 2rem;
  width: 75vw;
  border-radius: 0.5rem;
  border: 1px solid #d9d9e3;
  background: #ffffff;
  color: #000;
  outline: none;
}

.textarea:disabled {
  opacity: 0.5;
}

.textarea:focus {
  outline: none;
  border-color: #6b7280;
  box-shadow: 0 0 0 3px rgba(156, 163, 175, 0.5);
}

.textarea::placeholder {
  color: #6b7280;
}

.generatebutton {
  position: absolute;
  top: 0.87rem;
  right: 1rem;
  color: rgb(165, 162, 162);
  background: none;
  padding: 0.3rem;
  border: none;
  display: flex;
}

.loadingwheel {
  position: absolute;
  top: 0.2rem;
  right: 0.25rem;
}

.svgicon {
  transform: rotate(90deg);
  width: 1.5em;
  height: 1.5em;
  fill: $IaColor;
}

.generatebutton:hover {
  background: #e4575726;
  border-radius: 0.2rem;
}

.generatebutton:disabled {
  opacity: 0.9;
  cursor: not-allowed;
  background: none;
}

.messagelist {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-radius: 0.5rem;
  background: #f9fafb;
  overflow-x: clip;
}

.messagelistloading {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.usermessage {
  background: #ffffff;
  padding: 1.5rem;
  color: #000;
  padding: 8px 16px 20px 16px;
  margin: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.usermessagewaiting {
  padding: 1.5rem;
  color: #000;
  background: linear-gradient(to left, #07080938, #1a1c2024, #07080936);
  background-size: 200% 200%;
  background-position: -100% 0;
  animation: loading-gradient 2s ease-in-out infinite;
  animation-direction: alternate;
  animation-name: loading-gradient;
}

@keyframes loading-gradient {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.apimessage {
  background: #ffffff;
  padding: 8px 16px 20px 16px;
  color: #000;
  animation: fadein 0.5s;
  border-radius: 4px;

  margin: 20px;
  border-image: linear-gradient(97deg, #16cad3 0%, #16d384 100%) 1;
  border-width: 3px;
  border-style: solid;
  border-image-slice: 0 0 0 1;
  border-top-color: #e5e5e5;
  border-right-color: #e5e5e5;
  border-bottom-color: #e5e5e5;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.markdownanswer {
  line-height: 1.75;
  @extend .caption;
}

.markdownanswer a:hover {
  opacity: 0.8;
}

.markdownanswer a {
  color: #b13a3a;
  font-weight: 500;
}

.markdownanswer code {
  color: #15cb19;
  font-weight: 500;
  white-space: pre-wrap !important;
}

.markdownanswer ol,
.markdownanswer ul {
  margin: 1rem;
}

.boticon,
.usericon {
  margin-right: 1rem;
  border-radius: 0.1rem;
  height: 100%;
}

.markdownanswer h1,
.markdownanswer h2,
.markdownanswer h3 {
  font-size: inherit;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  flex-direction: column;
}

.cloud {
  width: 75vw;
  height: 68vh;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #d9d9e3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointsnormal {
  width: 90%;
  height: 90%;
}

.pointsdim {
  width: 90%;
  height: 90%;
  opacity: 0.25;
}

.footer {
  color: #5f6368;
  font-size: 0.8rem;
  margin: 1.5rem;
}

.footer a {
  font-weight: 500;
  color: #7a7d81;
}

.footer a:hover {
  opacity: 0.8;
}

/* Mobile optimization */
@media (max-width: 600px) {
  .main {
    padding: 1rem;
    max-height: 90vh;
  }

  .cloud {
    width: 22rem;
    height: 28rem;
  }
  .textarea {
    width: 22rem;
  }
  .topnav {
    border: 1px solid black;
    align-items: center;
    padding: 0.85rem 0.75rem 0.85rem 0.75rem;
  }

  .navlogo {
    font-size: 1.25rem;
    width: 20rem;
  }

  .markdownanswer code {
    white-space: pre-wrap !important;
  }

  .footer {
    font-size: 0.7rem;
    width: 100%;
    text-align: center;
  }
}
.loading {
  display: inline-flex;
  align-items: center;
}

.loading .spacer {
  margin-right: 2px;
}

.loading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 1px;
}

.loading span:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading span:nth-of-type(3) {
  animation-delay: 0.4s;
}

.loading2 {
  display: inline-flex;
  align-items: center;
}

.loading2 .spacer {
  margin-right: 2px;
}

.loading2 span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 1px;
}

.loading2 span:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading2 span:nth-of-type(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.iaBackground {
  background-image: $IaColor;
  text-align: center;
}

.chat__documentContainer {
  border-radius: 4px;
  border: 1px solid var(--lines-line-2, #e5e5e5);
  background: #fff;
  padding: 20px;
}
.category__container {
  border: 1px solid #e5e5e5;
  margin: 1rem !important;

  &__selected {
    @extend .category__container;
    border-radius: 4px;
    border: 1px solid #8c8d9d !important;
    background: #e6e9ef !important;
  }
}

.chat__sendIcon {
  cursor: pointer;
  fill: #e5e5e5 !important;
  &__main {
    fill: #18ceab !important;
    padding-bottom: 5px !important;
  }

  &__selected {
    fill: #8c8d9d !important;
  }
}
