.filter-tag {
  &__headers {
    display: flex;
    align-items: center;
  }

  &__icon {
    color: $primaryColorDefault;
  }

  &__container {
    padding: 1%;
  }

  &__check {
    & > input[type="checkbox"] {
      display: none;
    }

    & > input:checked + label {
      border: solid 0.5rem $primaryColorActive;
      border-radius: 0.8rem;
    }
  }
}
.tag {
  &__success {
    @extend .caption;
    background-color: $otherColorDefaultDark !important;
    border-radius: 4px;
    text-align: center;
    word-break: keep-all;
  }
}
