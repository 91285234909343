// FONT SIZES ---------------------------------------------------------------

$font-size-x-large: 4.2rem;
$font-size-large: 2.8rem;
$font-size-medium: 2.6rem;
$font-size-small: 2rem;
$font-size-heading: 1.6rem;
$font-size-subheading: 1.2rem;
$font-size-label: 1.6rem;
$font-size-button: 1.4rem;
$font-size-body: 1.4rem;
$font-size-caption: 1.2rem;
$font-size-link: 1.2rem;

// FONT WEIGHTS --------------------------------------------------------------

$medium: 500;
$regular: 400;
$bold: bold;
$font-weight-x-large: $medium;
$font-weight-large: $medium;
$font-weight-medium: $regular;
$font-weight-small: $bold;
$font-weight-heading: $bold;
$font-weight-subheading: $medium;
$font-weight-label: $regular;
$font-weight-button: $bold;
$font-weight-body: $regular;
$font-weight-caption: $regular;
$font-weight-link: $regular;

// FONT LINE HEIGHTS ---------------------------------------------------------

$line-height-x-large: 4.4rem;
$line-height-large: 3.2rem;
$line-height-medium: 3.2rem;
$line-height-small: 2.8rem;
$line-height-heading: 2.4rem;
$line-height-subheading: 1.6rem;
$line-height-label: 2.4rem;
$line-height-button: 2rem;
$line-height-body: 2rem;
$line-height-caption: 1.6rem;
$line-height-link: 1.6rem;

// FONT FAMILIES -------------------------------------------------------------

$font-family: "Roboto", sans-serif;
$font-family-heading: "Roboto", sans-serif;