.coachmark {
  &__container {
    background-color: transparent;
  }

  &__paragraph {
    padding: 2.4rem;
    background-color: #00374f;
    color: #fff;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  }
}
