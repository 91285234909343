
.text-again{
    @extend .caption;
    @extend .col-start
}

.cols-end{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.title-part{
    @extend .heading;
    @extend .col-start;
    color: $primaryColorDefault
}

.drag-color{
    color: $disabledColorBorder
}

.crud-titles{
    @extend .heading;
    @extend .col-start;
    padding-left: 1%;
    color: $defaultTextColor;

    &__titles-contract{
        @extend .crud-titles;
        color: $disableTextColor;
    }


}

.text-fields{
    width:100% 
}

.labels-to-sign{
    @extend .label;
    @extend .col-start;
}

.text-field-text{
    @extend .label;
    color: $disableTextColor !important;
}
.logo-primary-color{
    color: $primaryColorDefault
}