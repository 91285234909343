.sidebar__bottom__container {
  bottom: 0;
  position: absolute;
  width: 100%;
  justify-content: end;
  background-color: $backgroundMenusColor;
  margin-left: -15px !important;
  margin-top: 15px !important;
  z-index: 1000;
  padding: 10px;
}
.MuiDrawer-root {
  z-index: 1300 !important;
}
.custom__box {
  border-radius: 8px !important;
  border: 1px solid $disableTextColor !important;
}
.container {
  &__max-height {
    text-align: justify;
    max-height: 288px;
    overflow-y: scroll;
  }
}
