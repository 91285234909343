.ellipsis-container {
  position: relative;
  max-height: 48px; /* Display three lines of text */
  overflow: hidden;
}

.ellipsis-text {
  cursor: pointer;
  transition: max-height 0.3s ease;
}

.expanded {
  max-height: none; /* Expand to show full text */
}

.ellipsis-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $infoColor;
  padding: 0 5px;
  cursor: pointer;
  font-weight: 500;
  background-color: #f6f6f7 !important;

  &__white {
    @extend .ellipsis-overlay;
    background-color: white !important;
  }
}
