.input-group-container {
  display: flex;
  border: 1px solid $primaryBorderColorHover;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  &:hover {
    border: 1px solid $primaryColorHover;
  }

  &:focus-within {
    border: 1.5px solid $primaryColorActive;
  }

  &__error {
    @extend .input-group-container;
    border: 1px solid $errorColorDark !important;
  }
  &__searchBar {
    height: 48px !important;
    border-radius: 8px;
  }

  &__no-icon {
    @extend .input-group-container;
    width: 100%;
    padding: 0.375rem 0.75rem;
    &__country-list {
      @extend .input-group-container__no-icon;
      height: 50%;
      &__error {
        @extend .input-group-container__no-icon__country-list;
        border: 1px solid $errorColorDark !important;
      }
    }
  }

  &__no-icon-error {
    @extend .input-group-container;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid $errorColorDark !important;
  }
}

.input-group-search-clauses {
  border: 1px solid $primaryBorderColorHover;
  border-radius: 4px;
}

.container-icon {
  background-color: none;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  color: $primaryColorDefault;

  &__disabled {
    @extend .container-icon;
    color: $primaryBorderColorHover !important;
  }
}

.custom-input {
  @extend .label;
  border: none;
  // height: 4.8rem;
  width: 90%;

  &__disabled {
    background-color: $disabledColorDefault;
  }

  &__button {
    @extend .button;
    width: 100%;
    // height: 4rem;
    text-transform: none !important;
    padding: 10px !important ;
    box-shadow: none !important;

    &__withOutBackground {
      @extend .custom-input__button;
      background-color: transparent !important;
      text-transform: none !important;
      color: $primaryColorDefault !important;
    }

    &__primary-color {
      @extend .custom-input__button;
      background-color: $primaryColorDefault !important;
      text-transform: none !important;
      border-radius: 8px !important;

      &__forced {
        @extend .custom-input__button__primary-color;
        width: 150px;
        height: 40px;
      }
    }

    &__iaColor {
      @extend .custom-input__button;
      background-image: $IaColor !important;
    }

    &__disabled {
      @extend .custom-input__button;
      background-color: $disabledColorDefault !important;
      text-transform: none !important;
      color: $disableTextColor !important;
    }

    &__secondary-color {
      @extend .custom-input__button;
      color: $defaultTextColor !important;
      text-transform: none !important;
      background-color: $secondaryColorDefault !important;
      border-radius: 8px !important;
      border: 1px solid #00374f !important;
      &__forced {
        @extend .custom-input__button__secondary-color;
        width: 150px;
        height: 40px;
        &__disabled {
          @extend .custom-input__button;
          width: 150px;
          height: 40px;
          background-color: #6c757d;
        }
      }
      &__icon {
        @extend .custom-input__button__secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
      }
    }
    &__ia-color {
      @extend .custom-input__button;
      background: $IaColor !important;
      color: white !important;
    }

    &__link {
      color: $otherColorDefault !important;
      text-transform: none !important;
    }

    &__default-color {
      @extend .custom-input__button;
      background-color: $secondaryColorHover !important;
      text-transform: none !important;
      color: $defaultTextColor !important;
      padding: 0.3rem !important;
      border-radius: "4px" !important;
    }

    &__default-other-color-dark {
      @extend .custom-input__button;
      background-color: $otherColorDefaultDark !important;
      text-transform: none !important;
      color: $defaultTextColor !important;
      padding: 0.3rem !important;
    }

    &__no-hover {
      &:hover {
        color: white;
      }
    }

    &__default-white {
      @extend .custom-input__button;
      background-color: $primaryBorderColorDefault !important;
      text-transform: none !important;
      color: $defaultTextColor !important;
      padding: 0.3rem !important;
    }
  }

  &__data-table-icon {
    width: fit-content !important;
    height: fit-content !important;

    &__disabled {
      @extend .custom-input__data-table-icon;
      color: $disabledColorDefault !important;
    }
  }

  &__small {
    @extend .custom-input;
    height: 3rem;
    width: 100%;
  }
  &__error {
    color: $errorColorDark;
    border-color: $errorColorDark;
  }
}

.spinner {
  &__white-background {
    @extend .spinner;
    color: #ffffff !important;
  }
}

.clickeable {
  cursor: pointer;
}

.input-group-container-disabled {
  display: flex;
  border: 1px solid $primaryBorderColorHover;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.input-upload {
  opacity: 0;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0;
  left: -10000px;
  cursor: pointer;
  margin: 1rem 10rem 0 7.5rem;

  &__button {
    padding: 8px;
    background-color: #e7f9fc;
    font-weight: 700;
    color: #00374f;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1.5rem;
    align-items: center;

    &__error {
      @extend .input-upload__button;
      border: 1px solid #e44258 !important;
    }
  }

  &__button_primary_color {
    padding: 8px;
    background-color: $primaryColorDefault;
    font-weight: 700;
    color: $primaryBorderColorDefault;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1.5rem;
  }
}

.navigate-link {
  color: $primaryBorderColorDefault;
  text-decoration: none;
}

.navigate-link:hover {
  color: $primaryBorderColorDefault;
}

.form-check-color input:checked[type="checkbox"] {
  background-color: #00374f;
  border-color: #00374f;
}

p::selection {
  background: #71c9c1;
  text-shadow: 1px 1px #fff;
}
