
.button-primary-folders{
    @extend .button;
    text-transform: none !important;
    background-color: $primaryColorDefault !important;
    color: $primaryBorderColorDefault !important;
    height:36px;

    &__share{
        @extend .button-primary-folders;
        width: 167px;
       
    }

    &__create{
        @extend .button-primary-folders;
        width:109px !important;
 
    }

    &__upload{
        @extend .button-primary-folders;
        width: 198px;
    }

    &__select{
        @extend .button-primary-folders;
        width: 134px !important;
    }

    &__navigation{
        @extend .button-primary-folders;
        width:99px !important
    }

    &__small-button{
        @extend .button-primary-folders;
        width:36px !important
    }

    &__disabled{
        @extend .button-primary-folders;
        background-color: $disabledColorBorder !important;
        color: $disableTextColor !important
    }
  
}

.button-softblue-folders{
    @extend .button;
    text-transform: none !important;
    background-color: $secondaryColorDefault !important;
    color: $primaryColorDefault !important;
    height: 36px;

&__details{
    @extend .button-softblue-folders;
    width:190px;
  
}

&__identification{
    @extend .button-softblue-folders;
    width:232px;
}

&__labels{
    @extend .button-softblue-folders;
    width: 153px;

    &__disabled{
        @extend .button-softblue-folders;
        @extend .button-softblue-folders__labels;
        background-color: $disabledColorBorder !important;
        color: $disableTextColor !important
    }
}

&__filter{
    @extend .button-softblue-folders;
    width:86px;
}

&__small-filter{
    @extend .button-softblue-folders;
    width:36px !important;
    height:36px !important;

}

&__select{
    @extend .button-softblue-folders;
    width: 134px !important
}

&__navigation{
    @extend .button-softblue-folders;
    width:99px !important
}

&__disabled{
    @extend .button-softblue-folders;
    background-color: $disabledColorBorder !important;
    color: $disableTextColor !important
}
}
  


.internal-alerts{
    @extend .button;
    background-color: $secondaryColorDefault;
    width: 76px;
    height:36px;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}
.col-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.drawner-details{
display: flex;
justify-content: flex-end;
align-items: center;

&__back{
    @extend .button-softblue-folders;
    width:117px;
    height:44px;
}

&__edit{
    @extend .button-primary-folders;
    width: 193px;
    height: 44px;
}

&__labels{
    @extend .caption;
    background-color: $disabledColorDefault;
    border-radius: 0.5rem;
}
}

.confirmation{
    &__logo{
    width:90px;
    height:90px;
    }

    &__name{
    @extend .display-x-large;
    color: $primaryColorDefault;
    font-weight: $font-weight-caption;
    padding:0;
    }

    &__comment{
    @extend .display-small;
    color: $primaryColorDefault;
    font-weight: $font-weight-caption;
    }
}

.select-folder {
    cursor: pointer;
}

.select-folder:hover {
    background-color: $secondaryColorDefault !important;
}