.form {
  /* margin: 3rem 1rem 2rem 0; */
  font-family: Roboto, sans-serif;

  &__label-wrap {
    display: flex;
    align-items: baseline;
    margin: 1.5rem 0 0.4rem 0.8rem;
  }

  &__input {
    font-size: $font-size-label;
    font-weight: 100;
    line-height: 2.4rem;
    border-radius: 0.5rem;
    border: 0.1rem solid #c5c7d0;
    margin: 0 1rem;
    padding-right: 10rem;
    padding-left: 1rem;
  }

  &__title {
    height: 2.4rem;
    font-weight: 600;
    font-size: $font-size-heading;
  }

  &__select {
    width: 15.7rem;
    height: 3.6rem;
    border-radius: 0.4rem;
    background-color: #e7f9fc;
    color: #00374f;
    border: none;
    font-size: 1.5rem;
    padding-left: 1rem;
    font-weight: bold;
    margin-top: 1rem;
  }

  &__label {
    font-size: $font-size-body;
    font-style: normal;
    font-weight: normal;
    color: $defaultTextColor;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    &-description {
      font-size: $font-size-link;
      margin-left: 0.4rem;
    }

    &--text {
      font-weight: normal;
      width: 100%;
      font-size: 1.1rem;

      & > a {
        text-decoration: none;
        color: $defaultTextColor;
      }
    }

    &--error {
      @extend .form__label;
      border: 1px solid $errorColorDark !important;
      border-color: $errorColorDark !important;
      color: $errorColorDark !important;
      font-weight: normal;
    }
  }

  /*  &-offer {
    padding: 50px;

    &__content {
      display: flex;
      margin-top: 10px;
    }
    &__span {
      margin-top: 8px;
      width: auto;
      display: inline;
    }
    &__paragraph {
      line-height: 40px;
      text-align: justify;
      color: #707070;

      &--strong {
        @extend .form-offer__paragraph;
        font-weight: 800;
      }
    }
  }

  &__error {
    color: $color-error;
    font-size: $font-size-xxs;
    margin-left: 0.8rem;
  }

  &__wrap {
    display: flex;
  }

  &__input {
    display: block;
    border: 1px solid $color-font-body-inactive;
    border-radius: 8px;
    font-family: inherit;
    color: $color-font-body-dark;
    padding: 0.5rem 1rem;
    width: 100%;
    max-width: 30rem;
    font-size: 1.2rem;

    &-city {
      @extend .form__input;
      width: 150px;
      display: inline-block;
      margin-right: 10px;
    }

    &-day {
      @extend .form__input;
      width: 70px;
      display: inline-block;
      margin-right: 10px;
      margin-left: 10px;
    }

    &-month {
      @extend .form__input;
      width: 70px;
      display: inline-block;
      margin-right: 10px;
    }

    &-year {
      @extend .form__input;
      width: 80px;
      display: inline-block;
      margin-left: 10px;
    }

    &-gender {
      @extend .form__input;
      width: 150px;
      display: inline-block;
      margin-top: 20px;
    }

    &-name {
      @extend .form__input;
      width: 200px;
      display: inline-block;
      margin-right: 10px;
    }
    &-smallfield {
      @extend .form__input;
      width: 130px;
      display: inline-block;
      margin-right: 15px;
      font-size: 15px;
    }

    &-lastname {
      @extend .form__input;
      width: 200px;
      display: inline-block;
      margin-right: 10px;
    }

    &-company {
      @extend .form__input;
      width: 200px;
      display: inline-block;
    }

    &-rol {
      @extend .form__input;
      width: 200px;
      display: inline-block;
    }

    &--error {
      @extend .form__input;
      border-color: $color-error;
    }

    &:focus {
      border: 2px solid $color-secondary;
    }
    &::placeholder {
      color: $color-input-placeholder;
    }
  }

  &__select {
    border: 1px solid $color-font-body-inactive;
    border-radius: 8px;
    font-family: inherit;
    color: $color-font-body-dark;
    margin-right: 1rem;
    padding: 0.6rem 0.5rem;
    display: block;
    width: 100%;
    max-width: 30rem;

    &--sm {
      @extend .form__select;
      width: auto;
    }
  }

  &__upload {
    border: 1px solid $color-font-body-inactive;
    border-radius: 8px;
    text-align: center;
    padding: 3rem 2rem 2rem 2rem;
    max-width: 20rem;
    margin-right: 1.5rem;
    height: 90%;

    &-icon {
      height: 3rem;
      width: 3rem;
      background-color: $color-primary;
      margin: 0 auto 2rem auto;
    }

    &-description {
      color: $color-font-body-inactive;
      display: block;
      margin: 1.5rem auto 0 auto;
      text-align: center;
      font-size: $font-size-sm;
    }

    &-formats {
      margin-top: 2rem;
      font-size: $font-size-xxs;
      color: $color-font-body-dark;
    }

    &--error {
      @extend .form__upload;
      border-color: $color-error;
    }
  }

  &__add {
    font-size: 2.5rem;
    font-weight: bold;
    font-family: $font-family-heading;
    margin-left: 2rem;
    cursor: pointer;

    &--big {
      @extend .form__add;
      font-size: 7rem;
    }
  }

  &__textarea {
    border: 1px solid $color-font-body-inactive;
    border-radius: 10px;
    width: 100%;
    max-width: 30rem;
    padding: 1rem 2rem;
    font-family: inherit;
    font-size: $font-size-md;
  }
  &__responsive {
    @include tablet {
      padding: 2rem;
    }
  }
  &__invertResponsive {
    @include tablet {
      flex-direction: column-reverse;
    }
  }
  &__title {
    @include tablet {
      text-align: center;
    }
  }
  &__subtitle {
    margin-bottom: 1rem;
  } */
}

.label {
  font-family: Roboto, sans-serif;

  &__title {
    height: 2.4rem;
    font-weight: bold;
    font-size: $font-size-heading;
    color: $primaryColorDefault;
  }

  &__subtitle {
    font-size: $font-size-body;
    font-style: normal;
    font-weight: 600;
    color: $defaultTextColor;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__description {
    font-size: $font-size-body;
    font-style: normal;
    font-weight: normal;
    color: $defaultTextColor;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__description_name {
    font-size: $font-size-body;
    font-style: normal;
    font-weight: normal;
    margin-top: 1rem;
    color: $disableTextColor;
  }

  &__description__default {
    font-size: $font-size-body;
    font-style: normal;
    font-weight: normal;
    color: $defaultTextColor;
  }

  &__link {
    font-size: $font-size-body;
    color: $otherColorHover;
    cursor: pointer;
  }

  &__description__primary__hover {
    font-size: $font-size-body;
    font-style: normal;
    font-weight: normal;
    color: $primaryColorHover;
  }
}

.custom-checkbox {
  width: 2rem !important;
  height: 2rem !important;
  color: $primaryColorDefault !important;
}

.subClause-title {
  display: flex;
  align-items: center;
}

.contract_table th {
  padding: 1rem !important;
  border: 1px solid #b9b9b9 !important;
  background-color: #e7e7e7;
  font-weight: bold;
  font-size: $font-size-heading;
}

.contract_table td {
  padding: 1rem !important;
  border: 1px solid #b9b9b9 !important;
  font-size: $font-size-body;
  font-style: normal;
  font-weight: 400;
}

.MuiListItemText-primary {
  @extend .body-content__disabled;
}
.Mui-checked {
  color: $primaryColorDefault !important;
}
.info__container {
  border-radius: 15px;
  background: rgba(113, 201, 193, 0.08);
  color: #71c9c1 !important;
  padding: 5px;
  @extend .body-content;
}
