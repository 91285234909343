.blankspaces__box {
  .MuiAccordionSummary-root {
    background: var(--menu-secundary, #fafcfd);
  }
  .MuiAccordionDetails-root {
    background: rgba(0, 55, 79, 0.05);
  }
}
.MuiPaper-root.blankspaces__box {
  margin-bottom: 0 !important;
  box-shadow: none !important;
  margin-top: 0 !important;
}

.blankspaces__container {
  height: 77vh;
  overflow: auto;
  border-top: 1px solid var(--disabled-default, #e6e9ef);
  border-left: 1px solid var(--disabled-default, #e6e9ef);
  padding-right: 0 !important;
  padding-left: 0 !important;
  overflow-x: scroll;
}
