.tab-title {
  margin-top: 1% !important;
  width: 13%;
}

.tab-text {
  @extend .caption;
  padding: 3% 1% 0 1% !important;
}

.tab-cards {
  margin-top: 45px !important;

  &__folder {
    @extend .home-img;
    width: 35px;
    height: 35px;
    filter: invert(100%) sepia(8%) saturate(0%) hue-rotate(50deg)
      brightness(107%) contrast(107%);
  }

  &__button {
    @extend .cards__nav-button__icon;
    color: invert(100%) sepia(8%) saturate(0%) hue-rotate(50deg)
      brightness(107%) contrast(107%);
  }
}
