.background-principal-icon {
  background-color: $primaryColorDefault;
}

.principal-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  &__box {
    background-color: white;
    border-radius: 5px;
    width: 50%;
    height: 50%;


    &__svg-icon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.white-icon {
  width: 100%;
  height: 100%;
  padding: 35%;

  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.select-role {
  text-align: center;
  height: 100%;
  padding: 4% 0%;
}

.align-center {
  text-align: center;
}

.login-page-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-login {
  width: 90% !important;
  height: 90% !important;
  border-radius: 23px;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  overflow: hidden;
  margin: 5% 0%;
}

.container-forgot-password {
  border-radius: 23px;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  overflow: hidden;
}

.form-container-login {
  padding: 0% 8%;
  margin-top: 5% !important;
}

.form-container {
  margin-top: 10% !important;
  &__forgotPassword {
    @extend .form-container;
    padding: 0% 30%;
  }
}
