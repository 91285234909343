.image {
  &__selected {
    padding: 5px;
    border-radius: 8px;
    border: 4px solid var(--primary-active, #067bbd);
    background: #fff;
    box-shadow: 4px 4px 25px 3px rgba(0, 0, 0, 0.05);
  }

  &__notselected {
    padding: 5px;
    box-shadow: 4px 4px 25px 3px rgba(0, 0, 0, 0.05);
  }
}
.imageUpload {
  width: 20px;
  height: 150px;
}
.bot__image {
  transition: transform 5s ease;
  width: 100%;
  will-change: transform;
}

.transition-col {
  transition: width 1s ease;
}
