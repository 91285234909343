.background-register-bar {
    background-color: $primaryColorDefault;
    color: $primaryBorderColorDefault;
}

.button-bar {
    color: $primaryColorDefault !important;
    background-color: $primaryBorderColorDefault !important; 
    width: 70% !important;
    height: 40%;
    margin: 0;
  }
.button-bar > p {
    margin: 0 !important;
}

.delete-before::before {
    flex: 0 !important;
    padding: 3% !important;
}