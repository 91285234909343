.steps{
  width: 141px !important;
  height: 56px !important;
  border-radius: 0.8rem;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0% !important;

  &__inner{
display: flex;
justify-content: center;
align-items: center;

&__steps-text{
    @extend .heading__primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0% !important;
    }
  }
}