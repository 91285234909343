.inputFileModificado {
  position: relative;
  height: 100%;
  width: 80%;
  margin: 4% 18%;
}

.inputFileOculto {
  position: relative;
  text-align: right;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 2;
  width: inherit;
  height: inherit;
  cursor: pointer;
}

.inputParaMostrar {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  border: 2px dashed gray;
  width: inherit !important;
  height: inherit;
  border-radius: 2%;
}

.iconUpload {
    color: $disableTextColor;
}
