.display-x-large {
  font-family: $font-family;
  font-weight: $font-weight-x-large;
  font-size: $font-size-x-large;
  line-height: $line-height-x-large;

  &__primary-color {
    @extend .display-x-large;
    color: $primaryColorDefault;
  }

  &__warning-color {
    @extend .display-x-large;
    color: $warningColorDark;
  }
  &__error-color {
    @extend .display-x-large;
    color: $errorColorDark;
  }
}

.display-large {
  font-family: $font-family;
  font-weight: $font-weight-large;
  font-size: $font-size-large;
  line-height: $line-height-large;

  &__primary-color-hover {
    @extend .display-large;
    color: $primaryColorHover;
  }

  &__primary-color {
    @extend .display-large;
    color: $primaryColorDefault;
  }

  &__warning-color {
    @extend .display-large;
    color: $warningColorDark;
  }
  &__error-color {
    @extend .display-large;
    color: $errorColorDark;
  }
}

.display-medium {
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: $font-size-medium !important;
  line-height: $line-height-medium;
}

.display-small {
  font-family: $font-family;
  font-weight: $font-weight-small;
  font-size: $font-size-small !important;
  line-height: $line-height-small;
}

.heading {
  font-family: $font-family;
  font-weight: $font-weight-heading;
  font-size: $font-size-heading;
  line-height: $line-height-heading;

  &__primary-color {
    @extend .heading;
    color: $primaryColorDefault;
  }

  &__success-color {
    @extend .heading;
    color: $successColorDark;
  }

  &__error-color {
    @extend .heading;
    color: $errorColorDark;
  }

  &__warning-color {
    @extend .heading;
    color: $warningColorDark;
  }
  &__subtitle {
    @extend .heading;
    color: $subtitleTextColor;
  }

  &__primary-active {
    @extend .heading;
    color: $primaryColorActive;
  }
  &__primary-border {
    @extend .heading;
    color: $primaryBorderColorDefault;
  }

  &__default {
    @extend .heading;
    color: $defaultTextColor;
  }

  &__hover {
    @extend .heading;
    color: $primaryColorHover;
  }
}

.subheading {
  font-family: $font-family;
  font-weight: $font-weight-subheading;
  font-size: $font-size-subheading;
  line-height: $line-height-subheading;

  &__primary-active {
    @extend .subheading;
    color: $primaryColorActive;
  }
  &__warning-color {
    @extend .subheading;
    color: $warningColorDark;
  }
  &__error-color {
    @extend .subheading;
    color: $errorColorDark;
  }

  &__default {
    @extend .subheading;
    color: $defaultTextColor;
  }
}

.label {
  font-family: $font-family !important;
  font-weight: $font-weight-label !important;
  font-size: $font-size-label !important;
  line-height: $line-height-label !important;

  &__primary-color {
    @extend .label;
    color: $primaryColorDefault !important;

    &__page-title {
      @extend .label__primary-color;
      border-bottom: 3px solid $primaryColorDefault;
      text-transform: none !important;
    }
  }

  &__coachmark {
    @extend .label;
    color: $primaryMenuColor;
  }
}

.button {
  font-family: $font-family !important;
  font-weight: $font-weight-button !important;
  font-size: $font-size-button !important;
  line-height: $line-height-button !important;
  &__primary-Color-Active {
    @extend .label;
    color: $primaryColorActive !important;
  }
  &__warning-color {
    @extend .button;
    color: $warningColorDark;
  }
  &__error-color {
    @extend .button;
    color: $errorColorDark;
  }
}

.body-content {
  font-family: $font-family !important;
  font-weight: $font-weight-body !important;
  font-size: $font-size-body !important;
  line-height: $line-height-body !important;

  &__justify {
    @extend .body-content;
    text-align: justify;
  }

  &__center {
    @extend .body-content;
    text-align: center;
  }

  &__text-disabled {
    @extend .body-content;
    color: $disabledColorBorder;
  }

  &__disabled {
    @extend .body-content;
    color: $disableTextColor !important;
  }
}

.caption {
  font-family: $font-family !important;
  font-weight: $font-weight-caption !important;
  font-size: $font-size-caption !important;
  line-height: $line-height-caption;
  word-break: break-word;

  &__primary-color {
    @extend .caption;
    color: $primaryColorDefault;

    &__active {
      @extend .caption;
      color: $primaryColorActive;
    }
  }

  &__other-Color-Active {
    @extend .caption;
    color: $otherColorActive;
    cursor: pointer;
  }

  &__helper-text {
    @extend .caption;
    color: $disabledColorBorder;
  }

  &__justify {
    @extend .caption;
    text-align: justify;
  }
  &__disabled {
    @extend .caption;
    color: $disableTextColor;
  }

  &__warning {
    @extend .caption;
    color: $warningColorDark;
  }

  &__strong {
    @extend .caption;
    font-weight: bolder !important;
  }

  &__error {
    @extend .caption;
    color: $errorColorDark;
  }
  &__link {
    @extend .caption;
    color: $otherColorDefault !important;
    text-decoration: underline;
    cursor: pointer;
  }
  &__gray {
    @extend .caption;
    color: $textGray;
  }
}

.link {
  font-family: $font-family;
  font-weight: $font-weight-link;
  font-size: $font-size-link;
  line-height: $line-height-link;
}

.justify-text {
  text-align: justify;
}
.chip {
  padding: 1px 4px;
  font-size: 12px;
  border-radius: 4px;
  &__disabled {
    @extend .chip;
    border: 0px solid rgba(51, 51, 51, 0.3);
    background: var(--disabled-default, #e6e9ef);
  }
}

.text-ellipsis-t {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-ellipsis-t:hover {
  overflow: visible;
  display: inherit;
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.text-ellipsis-o {
  @extend .text-ellipsis-t;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
.gradient-text {
  background-image: $IaColor;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.word-break {
  &__break-all {
    word-break: break-all;
  }
}
