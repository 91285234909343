.custom-radio {
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px !important;
  &__container {
    padding-left: 2%;
    box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px !important;
    overflow-y: scroll;
    height: 100%;
    height: calc(100vh - 40rem);
  }
}
.MuiFormControlLabel-label {
  @extend .body-content;
}
