.cards {
  width: 424px !important;
  height: 200px !important;
  border-radius: 0.8rem;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  &__content {
    height: inherit;
  }

  &__disabled {
    @extend .cards;
    opacity: 0.5;
  }

  &__icon-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primaryColorDefault;
    color: white;
    border-radius: 0.8rem 0 0 0.8rem;
  }

  &__icon-svg {
    width: 56px !important;
    height: 56px !important;
  }

  &__body-section {
    display: flex;
    align-items: center;
  }

  &__nav-button {
    display: flex;
    justify-content: right;

    &__icon {
      background-color: $primaryColorDefault;
      color: white;
      width: 36px !important;
      height: 36px !important;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.cards-edit {
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  overflow: hidden;
  margin: 5% 0%;
  &__header {
    margin: 5% 0% !important;

    &__title {
      text-align: left;
    }

    &__icon {
      text-align: right;
    }
  }
}

.informative-cards {
  &__body-section {
    @extend .cards__body-section;
    padding: 1% !important;
  }
  &__medium {
    width: 336px !important;
    height: 112px !important;
    border-radius: 0.8rem;
    box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
    overflow: hidden;
    padding-left: 2% !important;

    &__icon-section {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primaryColorDefault;
      fill: white;
    }
  }

  &__small {
    width: 184px !important;
    height: 68px !important;
    border-radius: 0.8rem;
    box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
    overflow: hidden;
    padding-left: 2% !important;

    &__icon-section {
      padding: 10px !important;
      height: 52px !important;
      width: 52px !important;
      margin-top: 8px !important;
      margin-left: 6px !important;
      border-radius: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primaryColorDefault;
      color: white;
      cursor: pointer;
    }

    &__title {
      @extend .display-large__primary-color;
      padding-top: 4.9%;
      margin-bottom: 0 !important;

      &__warning {
        @extend .informative-cards__small__title;
        color: $errorColorDark !important;
      }
    }
  }

  &__content {
    margin: 3% 0% !important;
  }
}

.cards-document {
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.section-card {
  border-radius: 0.8rem;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  overflow: hidden;
  height: 100%;

  &__title {
    @extend .section-card;
    padding: 4% 5% !important;
  }
}

.cards-filter {
  width: 33.6rem !important;
}

.cards-document-informative {
  @extend .section-card;
  @extend .col-start;
  height: 76px !important;
  width: 424px !important;
}
